import React from "react";
import PropTypes, { number, string } from "prop-types";
import styled from "styled-components";
// import { Close } from "../shared/shared-components";

const STERKT = { val: 1, class: "sterkt" };
const MIDDELS = { val: 2, class: "middels" };
const SVAKT = { val: 3, class: "svakt" };

const TooltipColor = props => {
	const getDivs = level => {
		return (
			<TooltipLevelColor className={level.class}>
				<div />
				<div />
				<div />
			</TooltipLevelColor>
		);
	};
	return <div>{getDivs(props.level)}</div>;
};
const TooltipLevelColor = styled.div`
	display: flex;
	width: 100%;
	height: 3px;

	> div {
		background-color: #878787;
		height: 3px;
		width: 100%;
		margin: 0 3px 0 0;
		&:last-child {
			margin: 0;
		}
	}
	&.sterkt div {
		background-color: #4faa73;
	}
	&.middels div:nth-child(1),
	&.middels div:nth-child(2) {
		background-color: #e9ca90;
	}
	&.svakt div:nth-child(1) {
		background-color: #e25a47;
	}
`;

TooltipColor.propTypes = {
	level: PropTypes.shape({
		val: number.isRequired,
		class: string.isRequired
	}).isRequired
};

const Tooltip = React.forwardRef((props, ref) => {
	return (
		<TooltipWrap ref={ref}>
			<Close onClick={props.onClick}>X</Close>
			<TooltipTitle>Estimatet baseres på følgende kriterier:</TooltipTitle>
			<ul>
				<li>Boligens omsettelseshistorikk</li>
				<li>Områdets omsettelseshistorikk</li>
			</ul>
			<TooltipSeparator>Databasen oppdateres ukentlig.</TooltipSeparator>
			<div>
				<TooltipLevel>Sterkt</TooltipLevel>
				<TooltipColor level={STERKT} />
				<p>
					Datagrunnlaget for den oppsøkte boligen er sterkt. Oppgitt prisestimat kan ha svingninger, da vi ikke er i
					stand til å vurdere standarden på boligen.
				</p>
			</div>
			<div>
				<TooltipLevel>Middels</TooltipLevel>
				<TooltipColor level={MIDDELS} />
				<p>
					Datagrunnlaget for den oppsøkte boligen er middels, og det kan derfor forekomme større svingninger i
					prisestimatet. Det kan være flere grunner til dette. For eksempel at boligen ikke har blitt omsatt tidligere,
					eller at det er lenge siden det har blitt omsatt boliger i dette området.
				</p>
			</div>
			<div>
				<TooltipLevel>Svakt</TooltipLevel>
				<TooltipColor level={SVAKT} />

				<p>
					Datagrunnlaget for oppsøkt bolig er svakt, og vi har derfor ikke tilstrekkelig informasjon til å kunne gi et
					prisestimat. Hvis du ønsker en indikasjon, kan du gange oppgitt kvadratmeterpris med boligens bruttoareal.{" "}
				</p>
			</div>
		</TooltipWrap>
	);
});

const Close = styled.div`
	background-color: rgb(135, 135, 135);
	color: rgb(207, 209, 210);
	width: 14px;
	height: 14px;
	left: 10px;
	top: 10px;
	text-align: center;
	cursor: pointer;
	line-height: 13px;
	border-radius: 7px;
	font-size: 9px;
	line-height: 15px;
`;

const TooltipWrap = styled.div`
	font-size: 0.8em;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: calc(100% - 2em);
	max-width: 400px;
	margin: 25px auto 0;
	height: auto;
	padding: 1.6em;
	background-color: #cfd1d2;
	border-radius: 20px;
	z-index: 100;
	color: #231f20;
	display: none;
	@media (min-width: 1100px) {
		top: 0;
		right: 2em;
		left: auto;
		width: 250px;
		margin: 25px 0 0;

		&:before {
			position: absolute;
			content: "";
			width: 0;
			height: 0;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-right: 15px solid #cfd1d2;
			left: -15px;
			top: 70px;
		}
	}

	ul {
		margin: 1em 0 0 1em;
		padding: 0;
	}
	li {
		margin: 0;
		padding: 0;
	}
	p {
		margin-top: 5px;
		font-style: italic;
		font-weight: 100;
	}
`;

const TooltipTitle = styled.div`
	font-weight: bold;
	text-align: center;
	font-size: 1.4em;
	padding: 0 0 5px;
`;

const TooltipSeparator = styled.div`
	text-align: center;
	padding: 1em 0 2em;
	margin-bottom: 1.2em;
	position: relative;
	&:after {
		position: absolute;
		content: "";
		width: 100px;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 0;
		height: 3px;
		background-color: #878787;
	}
`;

const TooltipLevel = styled.div`
	font-style: italic;
	font-size: 1.4em;
`;

export default Tooltip;
