import React, { useState } from "react";
import styled from "styled-components";
import { Route } from "react-router-dom";
import SearchApartment from "./search-apartment";
import Apartment from "./apartment";
import clientFactory from "./apollo-client";
import { GlobalStyles } from "./global-styles";

// export const StateContext = React.createContext(null);

const client = clientFactory();

export const RangeContext = React.createContext(null);

const Kampanjeside = () => {
	const [range, setRange] = useState(null);

	return (
		<div className="kampanjeside">
			<GlobalStyles />
			<Route
				exact
				path="/:settprispaa(settprispa|settprispaa|settprispå|privatmeglerenverdi)"
				render={() => {
					return (
						<Container bg="https://cdn.reeltime.no/pm_assets/stillinger/PM_stillinger_bg_01.jpg">
							<Wrapper>
								<Title>PrivatMegleren Verdi</Title>
								<SettPrisPaa style={{ fontSize: "18px" }}>
									La oss gi deg et estimat på hva boligen eller fritidsboligen er verdt.
								</SettPrisPaa>
								<SettPrisPaa style={{ marginBottom: "30px" }}>
									Skriv inn adressen, så vil vi basert på tilgjengelig data gi deg et prisestimat. Ønsker du en nøyaktig
									prisvurdering eller E-takst kan du bestille&nbsp;
									<a href="https://privatmegleren.no/verdivurdering">verdivurdering&nbsp;her.</a>
								</SettPrisPaa>
								<SettPrisPaa style={{ marginBottom: "30px" }}>
									Forvent nyttige løsninger.{" "}
									<FakeLinkBecauseDesignersHateUsers>Forvent mer.</FakeLinkBecauseDesignersHateUsers>
								</SettPrisPaa>
								<SearchApartment client={client} />
								<Bar />
							</Wrapper>
						</Container>
					);
				}}
			/>
			<Route
				exact
				path="/:settprispaa(settprispa|settprispaa|settprispå|privatmeglerenverdi)/apartment/:id"
				render={({ match, history }) => {
					return (
						<RangeContext.Provider value={{ range, setRange }}>
							<HeightContainer>
								<Apartment client={client} match={match} history={history} />
							</HeightContainer>
						</RangeContext.Provider>
					);
				}}
			/>
		</div>
	);
};

export default Kampanjeside;

const HeightContainer = styled.div`
	min-height: 800px;
`;

const SettPrisPaa = styled.div`
	text-align: center;
	margin: 2em 0 0.4em;

	a {
		color: #e8c893;
		text-decoration: none;
	}
`;

const Daten = styled.img`
	width: 240px;
	@media (min-width: 600px) {
		width: 288px;
	}
`;

const Container = styled.div`
	padding: 180px 18px 0 18px;
	max-width: 600px;
	min-height: 700px;
	margin: 0 auto;
	align-self: center;
	@media (min-width: 600px) {
		&:after {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			content: "";
			background-image: url(${props => (props.bg ? props.bg : "none")});
			background-size: cover;
			background-position: center;
			background-attachment: fixed;
			background-repeat: no-repeat;
			z-index: 0;
			opacity: 0.7;
		}
	}
`;

const Wrapper = styled.div`
	position: relative;
	z-index: 1;
`;

const Title = styled.h1`
	font-family: "Domaine Display", sans-serif;
	text-align: center;
	font-size: 48px !important;
	font-weight: 600;
	margin: 0;

	@media (max-width: 600px) {
		font-size: 35px !important;
	}
`;

const Text = styled.div`
	margin: 6em 0 0;
	text-align: center;
	position: relative;
	&:before {
		content: "";
		height: 3px;
		width: 120px;
		position: absolute;
		background-color: #e8c893;
		top: -3em;
		left: 0;
		right: 0;
		margin: auto;
	}
`;

const Bar = styled.div`
	margin: 40px auto;
	max-width: 200px;
	width: 100%;
	height: 2px;
	background: #e8c893;
`;

const FakeLinkBecauseDesignersHateUsers = styled.span`
	color: #e8c893;
`;
