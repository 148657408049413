import React, { useState, useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter, Route } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Verdiutvikling from "./verdiutvikling";
import { formatMoney, roundUpToHundred, scrollToForm } from "./utils";
import SearchApartment from "./search-apartment";
import Priskvalitet from "./priskvalitet";
import { M2_PRICE } from "./calculator-gql";
import { RangeContext } from ".";
import { Verdivurdering } from "./global-styles";

const ApartmentInfo = props => {
	const rangeContext = useContext(RangeContext);

	const [correctedPrice, setCorrectedPrice] = useState(null);
	const [loadPrice, { called, loading, data: m2data }] = useLazyQuery(M2_PRICE, { client: props.client });

	const range = Math.ceil(props.data.apartment.PrisKvalitet / 15);

	useEffect(() => {
		if (range === 1) {
			loadPrice({
				variables: {
					postnr: props.data.apartment.PostNr
				}
			});
		}
		rangeContext.setRange(range);
	}, []);

	const { data } = props;
	const valuation = formatMoney(
		roundUpToHundred(correctedPrice) || roundUpToHundred(data.apartment.Verdi),
		0,
		".",
		"."
	);

	const korriger = e => {
		const kvmpris = props.data.apartment.Verdi / props.data.apartment.EstimertBRA;
		setCorrectedPrice(kvmpris * e.target.value);
	};

	/*
	const getPricePrSQM = () => {
		if (called && !loading && m2data) {
			return {
				enebolig:
					m2data.costEnebolig &&
					m2data.costEnebolig.length > 0 &&
					m2data.costEnebolig.find(el => el.document.Nybolig === false),
				rekkehus:
					m2data.costRekkehus &&
					m2data.costRekkehus.length > 0 &&
					m2data.costRekkehus.find(el => el.document.Nybolig === false),
				leilighet:
					m2data.costLeilighet &&
					m2data.costLeilighet.length > 0 &&
					m2data.costLeilighet.find(el => el.document.Nybolig === false)
			};
		}
		return null;
	};

	const costprsqm = useMemo(() => {
		return getPricePrSQM();
	}, [m2data]);
	*/

	return (
		<ApartmentInfoWrap>
			<Route
				exact
				path="/:settprispaa(settprispa|settprispaa|settprispå|privatmeglerenverdi)/apartment/:id(\d+)"
				render={() => {
					return (
						<>
							<Head>Søkeresultat</Head>
							<div style={{ overflow: "auto", marginTop: "-25px" }}>
								<SearchInputWrap hasBra={props.data.apartment.EstimertBRA}>
									<SearchApartment
										client={props.client}
										gatenavn={`${props.data.apartment.Gatenavn} ${props.data.apartment.HusNr} ${
											props.data.apartment.Oppgang ? props.data.apartment.Oppgang : ""
										}`}
										history={props.history}
									/>
								</SearchInputWrap>
								{props.data.apartment.EstimertBRA && (
									<KorrigerWrap>
										<label htmlFor="korrigerprom">Korriger BRA</label>
										<Korriger
											id="korrigerprom"
											placeholder="m2"
											type="tel"
											defaultValue={props.data.apartment.EstimertBRA}
											onChange={korriger}
										/>
									</KorrigerWrap>
								)}
							</div>
							{/*<Kilde />*/}
						</>
					);
				}}
			/>

			<>
				<DescText>Estimert verdi i dag</DescText>
				<Valuation>
					{valuation},- <span style={{ color: "#E9CA90", fontSize: "60px" }}>*</span>
				</Valuation>
			</>

			<>
				<Priskvalitet range={range} />
				{props.data.apartment.EstimertBRA && <Verdiutvikling data={props.data} />}
			</>
			<div style={{ marginTop: "2em" }}>
				<Verdivurdering>
					Ønsker du en nøyaktig verdivurdering?
					<button target="_blank" rel="noopener noreferrer" onClick={scrollToForm}>
						Kontakt megler
					</button>
				</Verdivurdering>
			</div>
			{/*)*/}
		</ApartmentInfoWrap>
	);
};

ApartmentInfo.propTypes = {
	data: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

const SearchInputWrap = styled.div`
	float: left;
	${props => (props.hasBra ? "width: calc(100% - 85px);" : "width: 100%;")}
	margin: 25px 0 0 0;
	font-size: 12px;
	@media (min-width: 600px) {
		font-size: 16px;
		${props => (props.hasBra ? "width: calc(100% - 120px);" : "width: 100%")}
	}
`;

const Head = styled.div`
	padding: 0;
	margin: 2em 0 2em;
	font-size: 2.2em;
	text-align: center;
	font-family: "Domaine Display", sans-serif;
	text-align: center;
	font-size: 48px !important;
	font-weight: 600;
	position: relative;
	line-height: 1.4;

	@media all and (max-width: 600px) {
		font-size: 35px !important;
	}

	&:after {
		content: "";
		width: 60px;
		height: 2px;
		margin: -0.6em auto;
		background-color: #e9ca90;
		position: absolute;
		left: 0;
		right: 0;
	}
	&:after {
		bottom: 0;
		margin: -0.8em auto;
	}
`;

const ApartmentInfoWrap = styled.div`
	text-align: center;
	margin-bottom: 2em;
`;

const DescText = styled.div`
	clear: both;
	font-size: 1.1em;
	margin-top: 3em;
	text-align: center;
`;

const Valuation = styled.div`
	font-size: 3em;
	font-family: "DomaineDisp", serif;
	font-weight: 100;
	color: #fff;
	line-height: 1;
	margin: 0 auto 0.2em;
	text-align: center;
	display: inline-block;
	@media (min-width: 600px) {
		font-size: 4em;
	}
`;

const KorrigerWrap = styled.div`
	width: 75px;
	float: left;
	margin: 0 0 0 10px;
	font-size: 12px;
	@media (min-width: 600px) {
		width: 110px;
		font-size: 16px;
	}

	label {
		text-align: center;
		font-size: 12px;
		@media (min-width: 600px) {
			font-size: 16px;
		}
	}
`;

const Korriger = styled.input`
	width: 100%;
	margin: 7px 0 0;
	font-size: 16px !important;
	padding: 15px !important;
	border-radius: 0;
	text-align: center;
	background-color: rgb(75, 75, 75) !important;
	color: #fff !important;
	border: none !important;
	-webkit-appearance: none;
	appearance: none;

	@media (min-width: 600px) {
		margin: 1px 0 0;
	}
`;
/*
const LavKvalitet = styled.div`
	margin: 3em 0 1em;
`;

const Pkvm = styled.div`
	display: flex;
	justify-content: space-around;
	img {
		width: 60px;
		@media (min-width: 600px) {
			width: 100px;
		}
	}
`;
*/
export default withRouter(ApartmentInfo);
