import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Chartkick, { AreaChart } from "react-chartkick";
import Highcharts from "highcharts";
import { calculateIncrease, scrollToForm } from "./utils";
import { Verdivurdering } from "./global-styles";

Chartkick.use(Highcharts);

const Verdiutvikling = props => {
	const { apartment } = props.data;

	if (!apartment) return null;

	const increase = calculateIncrease(apartment.Verdi90, apartment.Verdi);
	return (
		<VerdiUtvikling>
			<p>*Verdien du får oppgitt er kun et estimat og kan ikke brukes som e&#8209;takst/meglers verdivurdering.</p>
			<Verdivurdering>
				Ønsker du en nøyaktig verdivurdering?
				<button target="_blank" rel="noopener noreferrer" onClick={scrollToForm}>
					Kontakt megler
				</button>
			</Verdivurdering>
			<span>Verdiutvikling de siste tre månedene er: </span>
			<EndringProsent>{increase.toKr()} kr</EndringProsent>. Dette tilsvarer{" "}
			<EndringProsent>{increase.toPercent()}%</EndringProsent>
			<div
				style={{
					marginTop: "1em",
					height: "200px",
					position: "relative"
				}}
			>
				<PrisLabel>Pris</PrisLabel>
				<AreaChart
					height={200}
					library={{
						plotOptions: {
							series: {
								color: "#E9CA90",
								fillColor: "#E9CA90",
								pointPlacement: "on",
								states: {
									hover: {
										enabled: false
									}
								}
							},
							area: {
								fillColor: "#fff",
								pointStart: 0
							}
						},
						tooltip: {
							enabled: false
						},
						chart: {
							height: "200px",
							margin: [0, 0, 0, 0],
							backgroundColor: "transparent",
							plotBackgroundColor: "#21201F"
						},
						legend: {
							enabled: false
						},
						xAxis: {
							visible: false,
							startOnTick: false
						},
						yAxis: {
							startOnTick: false,
							gridLineColor: "#21201F",
							labels: {
								enabled: false
							},
							title: {
								style: {
									color: "#E9CA90",
									fontSize: "16px"
								}
							}
						}
					}}
					xtitle=""
					ytitle="Pris"
					min={Math.min(apartment.Verdi30, apartment.Verdi60, apartment.Verdi90, apartment.Verdi)}
					max={Math.max(apartment.Verdi30, apartment.Verdi60, apartment.Verdi90, apartment.Verdi)}
					data={{
						"90": apartment.Verdi30,
						"60": apartment.Verdi60,
						"30": apartment.Verdi90,
						Nå: apartment.Verdi
					}}
				/>
				<TimeLabel>Siste 90 dager</TimeLabel>
			</div>
		</VerdiUtvikling>
	);
};

Verdiutvikling.propTypes = {
	data: PropTypes.object.isRequired
};

const TimeLabel = styled.div`
	position: relative;
	z-index: 2;
	color: #e9ca90;
	top: 5px;
`;

const PrisLabel = styled(TimeLabel)`
	position: absolute;
	left: -30px;
	transform: rotateZ(-90deg);
	top: 93px;
`;

const VerdiUtvikling = styled.div`
	margin: 3em 0 2em;
	table {
		width: 100%;
	}
	td {
		padding: 1em 0 0;
		&:last-child {
			text-align: right;
		}
	}

	.barline {
		max-width: 800px;
		min-width: 100px;
	}

	span {
		font-size: 1em;
	}
`;

const EndringProsent = styled.span`
	color: #e9ca90;
	white-space: no-wrap;
`;

export default React.memo(Verdiutvikling);
