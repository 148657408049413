import React from "react";
import styled from "styled-components";
import Modal from "./modal";

const Loading = () => (
	<Modal>
		<LoadWrap>
			<Loader />
		</LoadWrap>
	</Modal>
);

const LoadWrap = styled.div`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3000;
`;

const Loader = styled.div`
	display: block;
	width: 64px;
	height: 100vh;
	position: fixed;
	animation: fadedown 0.3s ease-out;
	transform: translateY(calc(50vh - 32px));
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;

	&:after {
		content: " ";
		display: block;
		width: 60px;
		height: 60px;
		margin: 1px;
		border-radius: 50%;
		border: 5px solid #e9ca90;
		border-color: #e9ca90 transparent #e9ca90 transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}

	@keyframes fadedown {
		0% {
			opacity: 0;
			// transform: translateY(0%);
		}
		100% {
			opacity: 1;
			// transform: translateY(calc(50vh - 32px));
		}
	}
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default Loading;
