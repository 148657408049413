/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { withRouter, Route, Link } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import Enhet from "./enhet";
import { getApartments } from "./apartment-gql";
import { Address } from "./address-gql";
import { capitalize, onEnter } from "./utils";
// import Hr from "../shared/hr";

class SearchApartment extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			gatenavn: props.gatenavn || "",
			etasje: "",
			nummer: "",
			bokstav: "",
			adresseId: "",
			apartments: null
		};

		this.debouncedSearch = debounce(this.search, 500);
		this.debouncedUpdateSearch = debounce(this.updateSearch, 500);
		this.setInputValueWithSearch = this.setInputValue.bind(this, 1);
		this.setInputValueWithUpdateSearch = this.setInputValue.bind(this, 2);
		this.goToApartment = this.goToApartment.bind(this);
		this.setAddress = this.setAddress.bind(this);
		this.updateSearch = this.updateSearch.bind(this);
	}

	setInputValue(searchOption, e) {
		this.setState(
			{
				[e.target.name]: e.target.value
			},
			() => {
				if (searchOption === 1) {
					this.debouncedSearch();
				}
				if (searchOption === 2) {
					this.debouncedUpdateSearch();
				}
			}
		);
	}

	setAddress(e) {
		const adresseId = e.target.getAttribute("data-id");
		this.setState({ isLoading: true });
		this.props.client
			.query({
				query: getApartments,
				variables: {
					AdresseId: adresseId
				}
			})
			.then(result => {
				const leil = (result.data.apartments && result.data.apartments[0].document) || {};
				this.setState({
					isLoading: false,
					adresseId,
					apartments: result.data.apartments,
					nummer: leil.HusNr && leil.HusNr.toString(),
					gatenavn: leil.Gatenavn,
					bokstav: leil.Oppgang,
					etasje: leil && leil.EtasjeNr && leil.EtasjeNr.toString(),
					addresses: null
				});
			});
	}

	updateSearch() {
		const { adresseId, nummer, bokstav, etasje, gatenavn } = this.state;
		const variables = {
			HusNr: nummer,
			EtasjeNr: etasje,
			Oppgang: bokstav
		};
		if (gatenavn) {
			variables.Gatenavn = gatenavn;
		} else {
			variables.AdresseId = adresseId;
		}

		this.setState({ isLoading: true });

		this.props.client
			.query({
				query: getApartments,
				variables
			})
			.then(result => {
				if (!result.data.apartments[0]) {
					this.setState(state => {
						return { ...state, apartments: [] };
					});
					this.setState({ isLoading: false });
					return;
				}
				const leil = result.data.apartments[0].document;
				this.setState(state => {
					return {
						...state,
						isLoading: false,
						adresseId,
						apartments: result.data.apartments,
						gatenavn: leil.Gatenavn,
						nummer,
						bokstav,
						etasje
					};
				});
			});
	}

	search() {
		// const nummer = this.state.nummer;
		const gatenavn = this.state.gatenavn.length > 0 ? this.state.gatenavn : "";

		if (!gatenavn) {
			return false;
		}
		this.setState({
			apartments: null
		});
		this.setState({ isLoading: true });

		this.props.client
			.query({
				query: Address,
				variables: {
					address: gatenavn
				}
			})
			.then(res => {
				this.setState({
					isLoading: false,
					addresses: res.data.address
				});
			});
		return true;
	}

	goToApartment(e) {
		this.props.history.push(`/settprispå/apartment/${e.currentTarget.getAttribute("data-id")}`);
	}
	render() {
		return (
			<>
				<Route
					exact
					path="/endre"
					render={() => {
						return (
							<>
								<TopText />
								<SectionTitle className="section-title">Endre søk</SectionTitle>
							</>
						);
					}}
				/>
				<Route
					exact
					path="/"
					render={() => {
						return (
							<div id="om">
								<TopText>Tjenesten som gjør boligjakten enklere for deg</TopText>
								<SectionTitle className="section-title">
									<p>
										Som medlem av Nybygg Notis får du fortrinnsrett på nybygg før de slippes til resten av markedet. Med
										dette blir det enklere å være tidlig ute og holde seg oppdatert på prosjekter i områder man holder
										øye med.
									</p>

									<p>
										I tillegg til eksklusiv informasjon om kommende prosjekter, vil du også få en estimert
										verdivurdering av din nåværende bolig, samt mulighet til å følge prisutviklingen måned for måned.
									</p>
									<div />
									{/* hr */}
									<p>
										Sjekk ut kommende prosjekter og registrer deg <Link to="/registrer">her</Link>, eller start
										boligjakten i søkefeltet under ved å få et prisestimat på din nåværende bolig.
									</p>
								</SectionTitle>
							</div>
						);
					}}
				/>

				<div className="search">
					<MainInput
						type="text"
						placeholder="Søk adresse"
						name="gatenavn"
						autoComplete="off"
						value={this.state.gatenavn.toLowerCase()}
						onChange={this.setInputValueWithSearch}
					/>
				</div>
				{this.state.apartments && (
					<>
						<div className="search-params">
							<label>
								NUMMER
								<input
									type="tel"
									name="nummer"
									placeholder="2"
									value={this.state.nummer || ""}
									onChange={this.setInputValueWithUpdateSearch}
								/>
							</label>

							<label>
								BOKSTAV
								<input
									type="text"
									name="bokstav"
									placeholder="A"
									value={this.state.bokstav || ""}
									onChange={this.setInputValueWithUpdateSearch}
								/>
							</label>
							<label>
								ETASJE
								<input
									type="tel"
									name="etasje"
									placeholder="1"
									value={this.state.etasje || ""}
									onChange={this.setInputValueWithUpdateSearch}
								/>
							</label>
						</div>
						<div>
							{this.state.apartments.length > 0
								? this.state.apartments
										.sort((a, b) => {
											return a.document.BruksenhetNr.localeCompare(b.document.BruksenhetNr);
										})
										.map((apartment, i) => {
											return (
												<Enhet
													key={`apartment_${i}`}
													goToApartment={this.goToApartment}
													apartment={apartment.document}
												/>
											);
										})
								: null}
						</div>
					</>
				)}
				<>
					<BarLoader height="2px" width="100%" loading={this.state.isLoading} color="#E9CA90" />

					{this.state.addresses && this.state.addresses.length > 0 && (
						<div className="elements">
							<div className="elementListWrap">
								{this.state.addresses &&
									this.state.addresses.map(address => {
										return (
											<div
												key={address.addressId}
												data-id={address.addressId}
												className="element"
												tabIndex="0"
												onKeyDown={onEnter(this.setAddress)}
												onClick={this.setAddress}
											>
												{capitalize(address.street.toLowerCase())} {address.number}
												{address.letter && ` ${address.letter}`}, {capitalize(address.postalName.toLowerCase())}
											</div>
										);
									})}
							</div>
						</div>
					)}
				</>
			</>
		);
	}
}

SearchApartment.propTypes = {
	history: PropTypes.object.isRequired,
	gatenavn: PropTypes.string
};

const MainInput = styled.input`
	padding: 15px !important;
	-webkit-appearance: none;
	font-size: 16px !important;
	margin: 0 !important;
	background: rgb(75, 75, 75) !important;
	appearance: none;
`;

const TopText = styled.h1`
	font-size: 2em;
	margin-top: 3em;
	text-transform: uppercase;
	color: #e9ca90;
	font-weight: 600;
	font-family: "DomaineDisp", serif;
	text-align: center;
`;

const SectionTitle = styled.div`
	text-align: left;
	line-height: 1.4;
	font-weight: 300;
	font-size: 1em;
	margin: 0.3em 0 1em;
	text-align: center;
	&.grey {
		background-color: rgba(255, 255, 255, 0.1);
		padding: 6em 2em;
	}
`;

export default withRouter(SearchApartment);
