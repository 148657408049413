import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Modal from "./modal";
import Tooltip from "./tooltip";

const getRangeClass = range => {
	if (range <= 1) {
		return "redRange";
	}
	if (range <= 4) {
		return "yellowRange";
	}
	if (range <= 7) {
		return "greenRange";
	}
	return "redRange";
};

const Cell = props => {
	const el = useRef();
	useEffect(() => {
		const c = getRangeClass(props.range);
		setTimeout(() => {
			el.current.classList.add(c);
		}, 0);
	}, []);

	if (typeof window !== "undefined") {
		return (
			<CellWrap ref={el}>
				<div />
			</CellWrap>
		);
	}
	return null;
};
Cell.propTypes = {
	range: PropTypes.number.isRequired
};

const Priskvalitet = props => {
	const [tooltipVisible, toggleTooltip] = useState(false);
	const questionMark = useRef();
	const tooltipRef = useRef();

	const setTooltipPos = () => {
		if (tooltipVisible) {
			tooltipRef.current.style.display = "block";
			const top = window.pageYOffset + questionMark.current.getBoundingClientRect().top;
			const { left } = questionMark.current.getBoundingClientRect();
			tooltipRef.current.style.top = `${top - 100}px`;
			if (window.innerWidth >= 1100) {
				tooltipRef.current.style.left = `${left + 50}px`;
			} else {
				tooltipRef.current.style.left = "0";
			}
		}
	};

	useEffect(() => {
		setTooltipPos();
		window.addEventListener("resize", setTooltipPos);
		return () => {
			window.removeEventListener("resize", setTooltipPos);
		};
	}, [tooltipVisible]);

	const toggle = () => {
		toggleTooltip(!tooltipVisible);
	};

	return (
		<Datagrunnlag>
			<Bar>
				<CellWrap>Datagrunnlag</CellWrap>
				<Cell range={props.range} />
				<Cell range={props.range} />
				<Cell range={props.range} />
			</Bar>
			<Qm title="Om datagrunnlaget" ref={questionMark} onClick={toggle}>
				?
			</Qm>
			{tooltipVisible && (
				<Modal>
					<Tooltip onClick={toggle} ref={tooltipRef} />
				</Modal>
			)}
		</Datagrunnlag>
	);
};

Priskvalitet.propTypes = {
	range: PropTypes.number.isRequired
};

const Datagrunnlag = styled.div`
	display: flex;
	width: 100%;
`;

const Bar = styled.div`
	display: flex;
	width: 100%;
`;
const CellWrap = styled.div`
	width: 25%;
	margin: 0 2px;
	background-color: #21201f;
	overflow: hidden;

	&:first-child {
		width: 45%;
		background-color: #21201f;
		color: #e9ca90;
		border-radius: 10px 0 0 10px;
		text-transform: uppercase;
		font-size: 0.7em;
		padding: 2px;
		line-height: 1.9;
		@media (min-width: 600px) {
			width: 25%;
		}
	}
	&:last-child {
		border-radius: 0 10px 10px 0;
	}

	div {
		width: 0%;
	}

	&.redRange {
		div {
			width: 100%;
			height: 100%;
			background-color: #e25a47;
		}
		&:nth-child(2) {
			div {
				transition: all 1s ease-out 0.6s;
				background-color: #e25a47;
			}
		}
		&:nth-child(3) {
			div {
				background-color: transparent;
			}
		}
		&:last-child {
			div {
				background-color: transparent;
			}
		}
	}
	&.yellowRange {
		div {
			width: 100%;
			height: 100%;
			background-color: #e9ca90;
		}
		&:nth-child(2) {
			div {
				transition: all 0.6s linear 0.6s;
				background-color: #e9ca90;
			}
		}
		&:nth-child(3) {
			div {
				transition: all 1s ease-out 1.2s;
				background-color: #e9ca90;
			}
		}
		&:last-child {
			div {
				background-color: transparent;
			}
		}
	}

	&.greenRange {
		div {
			width: 100%;
			height: 100%;
			background-color: #4faa73;
		}
		&:nth-child(2) {
			div {
				transition: all 0.6s linear 0.6s;
				background-color: #4faa73;
			}
		}
		&:nth-child(3) {
			div {
				transition: all 0.6s linear 1.2s;
				background-color: #4faa73;
			}
		}
		&:last-child {
			div {
				transition: all 1s ease-out 1.8s;

				background-color: 4faa73;
			}
		}
	}
`;

const Qm = styled.div`
	color: #e9ca90;
	padding: 0 5px;
	cursor: pointer;
`;

export default Priskvalitet;
