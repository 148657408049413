/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { capitalize, onEnter } from "./utils";

const Enhet = props => {
	const { apartment } = props;

	return (
		<div
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex="0"
			className="enhet"
			data-id={apartment.id}
			onKeyDown={onEnter(props.goToApartment)}
			onClick={props.goToApartment}
		>
			<div>
				{`${capitalize(apartment.Gatenavn.toLowerCase())} ${apartment.HusNr}${
					apartment.Oppgang ? ` ${apartment.Oppgang}` : ""
				}, ${capitalize(apartment.KommuneNavn.toLowerCase())}`}
			</div>
			<div className="enhet-info">
				<div>{apartment.EtasjeNr ? apartment.EtasjeNr : 1}. etasje</div>
				<div>
					{apartment.Bruksareal} <span dangerouslySetInnerHTML={{ __html: "m&sup2;" }} />
				</div>
				<div>{apartment.BruksenhetNr}</div>
			</div>
		</div>
	);
};

Enhet.propTypes = {
	apartment: PropTypes.object.isRequired,
	goToApartment: PropTypes.func.isRequired
};

export default Enhet;
