/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import Context from "@common/website/components/context/shared_utils";
import { GET_APARTMENT } from "./apartment-gql";
import Loading from "./loader";
import ApartmentInfo from "./apartment-info";
import { useForm } from "./useForm";
import Modal from "./modal";
import fetch from "isomorphic-unfetch";
import { RangeContext } from ".";

const Apartment = props => {
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [source, setSource] = useState("settprispaa");
	const sharedUtils = useContext(Context);
	const rangeContext = useContext(RangeContext);
	const range = rangeContext.range;

	useEffect(() => {
		if (props.history.location.search.indexOf("recheck=it") > -1) {
			window.sessionStorage.recheckit = true;
			setSource("settprispaa-recheckit");
		}
	}, []);

	const image = "https://cdn.reeltime.no/pm_assets/img/bilde_pmverdi.jpg";

	const { fields, handleSubmit, handleChange, handleReset } = useForm({
		fields: [
			{
				type: "text",
				name: "adresse",
				label: "Adresse",
				placeholder: "Adresse",
				default: "",
				value: "",
				valid: true,
				validation: {
					exist: true
				}
			},
			{
				type: "text",
				name: "postnummer",
				label: "Postnummer *",
				placeholder: "1234",
				default: "",
				value: "",
				valid: true,
				validation: {
					valid: true,
					match: true
				}
			},
			{
				type: "text",
				name: "telefon",
				label: "Telefon *",
				info: "(Brukes til å kontakte deg)",
				placeholder: "00000000",
				default: "",
				value: "",
				valid: true,
				validation: {
					valid: true,
					match: true
				}
			},
			{
				type: "text",
				name: "epost",
				label: "E-post *",
				info: "(Brukes til å kontakte deg)",
				placeholder: "E-post*",
				default: "",
				value: "",
				valid: true,
				validation: {
					valid: true,
					match: true
				}
			},
			{
				type: "hidden",
				name: "to",
				default: "",
				value: "",
				valid: true,
				validation: {
					valid: true,
					match: true
				}
			},
			{
				type: "hidden",
				name: "src",
				default: "",
				value: "settprispaa",
				valid: true
			},
			{
				type: "hidden",
				name: "fromurl",
				default: "",
				value: "https://privatmegleren.no/settprispå",
				valid: true
			},
			{
				type: "submit",
				name: "send",
				default: "",
				value: "Kontakt meg",
				valid: true
			}
		],
		validateOnSubmit: true,
		validateOnChange: false,
		submitted: async () => {
			const fd = new FormData();
			const data = fields.reduce((prev, cur) => {
				if (prev[cur.name]) throw new Error("Tried creating already existing property");
				if (!cur.valid) throw new Error("Invalid form");
				if (cur.name === "src") {
					cur.value = source;
				}
				prev[cur.name] = cur.value;
				fd.append(cur.name, cur.value);
				return prev;
			}, {});

			try {
				const res = await sharedUtils.digger.formSubmit({
					zip: data.postnummer,
					type: "Verdivurdering"
				});

				data.digger_id = res.result.id;
				fd.append("digger_id", res.result.id);
				const result = await fetch("https://privatmegleren.no/api/verdivurdering", {
					body: fd,
					method: "post",
					mode: "cors"
				});
				result.json().then(e => {
					if (e.status === "error") {
						return alert(e.result);
					}
					setFormSubmitted(true);
					handleReset();
				});
			} catch (e) {
				console.log(e);
			}
		}
	});

	const close = e => {
		e.preventDefault();
		setFormSubmitted(false);
	};

	const { loading, error, data } = useQuery(GET_APARTMENT, {
		fetchPolicy: "network-only",
		client: props.client,
		variables: { apartmentId: parseInt(props.match.params.id, 10) }
	});
	if (error) {
		console.log("error: ", error);
	}
	if (loading) {
		return <Loading />;
	}

	return (
		<>
			<AnotherPointlessContainer bg={image} range={range}>
				<div className="gradient"></div>
				<ThatOtherWrapperContainer>
					{formSubmitted && (
						<Modal>
							<BG>
								<Takk>
									Takk for din henvendelse<Close onClick={close}>Lukk</Close>
								</Takk>
							</BG>
						</Modal>
					)}
					<ApartmentInfo client={props.client} data={data} />
				</ThatOtherWrapperContainer>
			</AnotherPointlessContainer>
			<ThatOtherWrapperContainer>
				<Form onSubmit={handleSubmit}>
					<SubmitMessage>Send inn skjemaet, så vil en av våre meglere kontakte deg</SubmitMessage>
					{fields.map(field => {
						return (
							<React.Fragment key={field.name}>
								<Label>
									{field.label} <span>{field.info}</span>
								</Label>

								<Input
									type={field.type}
									name={field.name}
									className={"form-input-" + field.name + " " + (!field.valid ? "invalid" : "")}
									placeholder={field.placeholder}
									value={field.value}
									onChange={handleChange}
								/>
							</React.Fragment>
						);
					})}
				</Form>
			</ThatOtherWrapperContainer>
		</>
	);
};

const AnotherPointlessContainer = styled.div`
	position: relative;

	background-position: ${props => (props.range === 1 ? "center -100px" : "center 0")};
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	width: 100%;
	margin: 0 auto;
	padding: 4em 0 0;

	@media (min-width: 600px) {
		background-image: url(${props => props.bg});
	}

	@media (min-width: 1200px) {
		background-position: 0 -300px;
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.1);
	}
`;

const ThatOtherWrapperContainer = styled.div`
	position: relative;
	z-index: 2;
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
	padding: 0 2em 1em;
`;

const Label = styled.label`
	span {
		color: rgb(100, 100, 100);
	}
`;

const Close = styled.div`
	background-color: #333;
	color: #e9ca90;
	font-weight: 600;
	width: 100px;
	text-align: center;
	font-size: 1rem;
	left: 0;
	right: 0;
	margin: 1em auto;
	padding: 0.4em;
	max-width: 100px;
	cursor: pointer;
`;

const BG = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	z-index: 1000;
`;
const Takk = styled.div`
	font-size: 2em;
	text-align: center;
	color: #e9ca90;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 100px;
`;

const Form = styled.form`
	margin: 3em 0 0;
`;

const SubmitMessage = styled.div`
	text-align: center;
	margin: 0 0 1em;
`;

const Input = styled.input`
	color: rgb(255, 255, 255);
	background: rgb(24, 24, 25);
	border: none;
	padding: 18px;
	font-size: 16px;
	width: 100%;
	height: 53px;
	margin: 0 0 10px;
	padding: 0 15px;
	-webkit-appearance: none;
	appearance: none;

	&::placeholder {
		color: rgb(100, 100, 100);
	}
	&.invalid {
		border: 1px solid red;
	}

	&.form-* {
		font-weight: 100;
		font-size: 0.8em;
	}
	&.form-input-send {
		background-color: #e8c893;
		color: #242525;
		margin: 10px 0 0;
		font-size: 16px;
		cursor: pointer;
		&:hover {
			background-color: #242525;
			color: #e8c893;
		}
	}
`;

export default Apartment;
