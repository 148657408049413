/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
	try {
		decimalCount = Math.abs(decimalCount);
		// eslint-disable-next-line no-restricted-globals
		decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

		const negativeSign = amount < 0 ? "-" : "";

		const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
		const j = i.length > 3 ? i.length % 3 : 0;

		return (
			negativeSign +
			(j ? i.substr(0, j) + thousands : "") +
			i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
			(decimalCount
				? decimal +
				Math.abs(amount - i)
					.toFixed(decimalCount)
					.slice(2)
				: "")
		);
	} catch (e) {
		console.log(e);
	}
};

export const setCookie = (cname, cvalue, exdays) => {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = `expires=${d.toUTCString()}`;
	document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = cname => {
	const name = `${cname}=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

export const onEnter = callback => {
	return e => {
		if (e.keyCode === 13) {
			callback(e);
		}
	};
};

export const onEscape = callback => {
	return e => {
		if (e.keyCode === 27) {
			callback(e);
		}
	};
};

export const capitalize = str => {
	const words = str.split(" ");
	const fixedwords = words.map(word => {
		const lower = word.substr(1, word.length);
		const firstLetter = word.substring(0, 1).toUpperCase();
		return firstLetter + lower;
	});
	return fixedwords.join(" ");
};

export const titleCase = str => {
	const splitStr = str.toLowerCase().split(" ");
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
};

export const calculateIncrease = (oldNum, newNum) => {
	const difference = newNum - oldNum;

	return {
		toPercent() {
			return ((difference / oldNum) * 100).toFixed(2);
		},
		toKr() {
			return formatMoney(difference, 0, ".", ".");
		},
		toSum() {
			return difference;
		}
	};
};

export const roundUpToHundred = val => {
	return Math.ceil(val / 100000) * 100000;
};

export const scrollToForm = e => {
	e.preventDefault();
	document.getElementsByClassName("form-input-send")[0].scrollIntoView({ block: "end", behavior: "smooth" });
};
