import { useState } from "react";
export const useForm = args => {
	const [fields, setFields] = useState(args.fields);
	const handleReset = () => {
		setFields(args.fields);
	};
	const handleChange = e => {
		const f = fields.map(item => {
			if (item && item.name === e.target.name) {
				item = Object.assign({}, item, { value: e.target.value });
			}
			return item;
		});
		setFields(f);
	};
	const handleSubmit = e => {
		e.preventDefault();
		if (typeof args.validateOnSubmit === "boolean" && args.validateOnSubmit) {
			validate();
		}
		if (typeof args.submitted === "function") {
			args.submitted();
		}
	};
	const validate = () => {
		const res = fields.reduce((prev, cur, index, f) => {
			f[index].valid = true;
			if (cur.name === "telefon" && cur.value.length < 6) {
				f[index].valid = false;
			}
			if (cur.name === "epost" && cur.value.length < 3) {
				f[index].valid = false;
			}
			if (cur.name === "postnummer" && (cur.value.length !== 4 || isNaN(cur.value))) {
				f[index].valid = false;
			}
			prev.push(f[index]);
			return prev;
		}, []);
		setFields(res);
	};

	return { fields, handleSubmit, handleChange, handleReset };
};
